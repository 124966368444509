import PropTypes from "prop-types";
import List from "app/pages/.shared/List";
import "./AlertMessage.scss";
import classNames from "classnames";
import { ALERT_TYPE } from "app/constants";
import { alertTypeProptypes } from "app/utils/propTypes";
import IconCircleTick from "app/pages/.shared/static/icons/IconCircleTick";

const AlertMessage = ({ errors, message, alertType, children }) => {
	let icon = <i className="icon icon--info-blue" />;

	if (alertType === ALERT_TYPE.SUCCESS) {
		icon = <IconCircleTick />;
	} else if (alertType === ALERT_TYPE.ERROR) {
		icon = false;
	}

	return (
		<div
			role="alert"
			className={classNames("alert-message", {
				"alert-message--error": alertType === ALERT_TYPE.ERROR,
				"alert-message--success": alertType === ALERT_TYPE.SUCCESS,
			})}
		>
			{message && (
				<div className="alert-message__message">
					{icon}
					<div className="alert-message__text" data-testid="alert-message">
						{message}
					</div>
				</div>
			)}
			{children}
			{errors.length > 0 ? <List items={errors} /> : false}
		</div>
	);
};

AlertMessage.defaultProps = {
	errors: [],
};

AlertMessage.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
	alertType: alertTypeProptypes,
};

export default AlertMessage;
