import { useFlagship } from "@flagship.io/react-sdk";
import { memo, useCallback, useContext, useState } from "react";
import AppGlobalsContext from "app/AppGlobalsContext";
import classNames from "classnames";
import groupBy from "lodash/groupBy";
import { isServerSide } from "app/utils/utils";
import "./DevInfos.scss";
import IconArrowUp from "app/pages/.shared/static/icons/IconArrowUp";
import IconArrowDown from "app/pages/.shared/static/icons/IconArrowDown";

const DevInfos = () => {
	const [expand, toogleExpand] = useState(false);

	const { flagsData = [] } = useFlagship();

	const { envVars = {} } = useContext(AppGlobalsContext);

	const environment = envVars.ENVIRONMENT;

	const showDevInfos =
		environment === "test" || environment === "canary" || environment === "preprod";

	const useCases = groupBy(flagsData, "slug");

	const toggle = useCallback(() => {
		toogleExpand(!expand);
	}, [expand]);

	const devInfosClass = classNames("dev-infos", {
		"dev-infos--collapse": !expand,
	});

	return (
		showDevInfos && (
			<div className={devInfosClass}>
				{!isServerSide && (
					<div>
						<h4 className="dev-infos__release">release : {window.__VERSION__}</h4>
						<div className="dev-infos__close" onClick={toggle}>
							{expand ? <IconArrowUp /> : <IconArrowDown />}
						</div>
					</div>
				)}
				{Object.keys(useCases).length > 0 && (
					<>
						<h4 className="dev-infos__title">Use cases flagship actifs : </h4>
						<div className="dev-infos__list">
							{Object.keys(useCases).map((useCase, index) => {
								return (
									<div key={index} className="dev-infos__item">
										<div className="dev-infos__slug">{useCase}</div>
										<ul className="dev-infos__flags">
											{useCases[useCase].map((flagData = {}, index) => {
												return (
													<li key={index} className="dev-infos__flag">
														{flagData.key} :{" "}
														<b>{String(flagData.value)}</b>
													</li>
												);
											})}
										</ul>
									</div>
								);
							})}
						</div>
					</>
				)}
			</div>
		)
	);
};

DevInfos.propTypes = {};

export default memo(DevInfos);
